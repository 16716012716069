import React, { useState } from "react";
import ListView from "./ListView.js";
import BoardView from "./boardView.js";

function Body({ data, searchForm }) {
  // Set the default tab to 'LIST'
  const [activeTab, setActiveTab] = useState("LIST");

  return (
    <>

      <div className="sm:ml-64 p-8 bg-white shadow rounded-lg">

        {searchForm}




        <div className="tab-content">
      
          <div className="relative overflow-x-auto rounded-md">
      <table className="w-full text-sm text-left text-gray-500">
        {/* header */}
        <thead className="text-xs text-gray-400 bg-gray-50">
          <tr>
            <th scope="col" className="px-6 py-3">
              ID
            </th>
            <th scope="col" className="px-6 py-3">
              Client Name
            </th>
            <th scope="col" className="px-6 py-3">
              Company Name
            </th>
            <th scope="col" className="px-6 py-3">
              Client Email
            </th>
            <th scope="col" className="px-6 py-3">
              Client Tags
            </th>
            <th scope="col" className="px-6 py-3">
              Client Status
            </th>
          </tr>
        </thead>

        {/* body of table */}
        <tbody>
          {data.map((item) => (
            <tr key={item.id} className="bg-white border-b">
              <td className="px-6 py-4">{item.id}</td>
              <td className="px-6 py-4">{item.client_name}</td>
              <td className="px-6 py-4">{item.company_name}</td>
              <td className="px-6 py-4">{item.client_email}</td>
              <td className="px-6 py-4">{item.client_tags}</td>
              <td className="px-6 py-4">{item.client_status}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
        </div>
        {/* tabs */}
      </div>
      {/* main body */}
    </>
  );
}

export default Body;

import React from 'react'
import Sidebar from '../../comp/sidebar'

function saio() {
  return (
    <>
    <Sidebar></Sidebar>
    <div className="sm:ml-64 h-screen bg-white shadow rounded-lg">
      <div className="h-screen ">
        <iframe
          className=" min-w-full mx-auto my-auto h-full border-none"
          src="https://saasautomation.gitbook.io/lead-gen-agency-automation/"
          width="100%"
          height="100%"
        ></iframe>
      </div>
    </div>
  </>
  )
}

export default saio

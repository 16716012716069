import React, { useState, useEffect, useRef } from 'react';
import { motion } from 'framer-motion';

// Define constants for coordinates
const FIRST_DIV_COORDS = { x: '50vw', y: '10vh' }; // Example values
const SECOND_DIV_COORDS = { x: '70vw', y: '-25vh' }; // Example values

function App() {
  const divRefs = [useRef(null), useRef(null)];
  const [currentDivIndex, setCurrentDivIndex] = useState(0);

  const handleScroll = (event) => {
    event.preventDefault(); // Prevent default scrolling
    const direction = event.deltaY > 0 ? 1 : -1; // Determine scroll direction
    let newIndex = currentDivIndex + direction;

    // Ensure newIndex stays within bounds
    newIndex = Math.max(0, Math.min(newIndex, divRefs.length - 1));

    if (newIndex !== currentDivIndex) {
      const scrollToElement = divRefs[newIndex].current;
      scrollToElement.scrollIntoView({ behavior: 'smooth' });
      setCurrentDivIndex(newIndex);
    }
  };

  useEffect(() => {
    window.addEventListener('wheel', handleScroll, { passive: false });
    return () => window.removeEventListener('wheel', handleScroll);
  }, [currentDivIndex]);

  return (
    <>
      <div ref={divRefs[0]} className="items-center m-auto justify-between flex min-h-screen bg-blue-300">
        <div className=''>First element here</div>
        <p>First Div Content</p>
      </div>
      <div ref={divRefs[1]} className="items-center m-auto justify-between flex min-h-screen bg-red-300">
        <p>Second Div Content</p>
        <div className=''>Second element here</div>
      </div>

      {/* Movable content */}
      <motion.div
  initial= {FIRST_DIV_COORDS}
  animate={currentDivIndex === 0 ? FIRST_DIV_COORDS : SECOND_DIV_COORDS}
  transition={{ type: 'spring', stiffness: 50 }}
  className="absolute p-4 bg-white rounded shadow"
>
  Movable Content
</motion.div>
    </>
  );
}

export default App;

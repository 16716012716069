import React, { useState, useEffect } from "react";
import Sidebar from "../../comp/sidebar";
import Apmbody from "./apmbody";
import supabase from "../../comp/supabaseClient";

const Clients = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      let { data: fetchedData, error } = await supabase
        .from("cloudflareCreds")
        .select("*");

      console.log(fetchedData);

      if (error) {
        console.error("Error fetching data: ", error);
      } else {
        setData(fetchedData);
      }
    };

    fetchData();
  }, []);

  return (
    <>
      <Sidebar />
      <Apmbody data={data} />
    </>
  );
};

export default Clients;

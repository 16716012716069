import React, { useEffect, useState } from "react";
import Sidebar from "../../comp/sidebar";
import getApiKeyByEmail from "../../pages/agency-home/apiKey";
import supabase from '../../comp/supabaseClient';

export default function LilyAI() {
  const [apiKey, setApiKey] = useState('');
  const [userEmail, setUserEmail] = useState('');

  useEffect(() => {
    async function fetchData() {
      const user = await supabase.auth.getUser();
      const userEmail = user.data.user.email;
      setUserEmail(userEmail);

      const apikey = await getApiKeyByEmail(userEmail, "team_users");
      setApiKey(apikey);
    }

    fetchData();
  }, []);

  return (
    <>
      <Sidebar></Sidebar>
      <div className="sm:ml-64 h-screen bg-white shadow rounded-lg">
        <div className="h-screen ">
          <iframe
            src={`https://bot.typebot.kinge.work/lily-start-8shh7or?apikey=${apiKey}&userEmail=${userEmail}`}
            className="mx-auto my-auto w-1/2 h-full border-none"
          ></iframe>
        </div>
      </div>
    </>
  );
}

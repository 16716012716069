import { BrowserRouter as Router, Routes, Route, Navigate, useNavigate } from "react-router-dom";

import { useState, useEffect } from "react";
import supabase from "./comp/supabaseClient.js";
import Success from "./pages/successPage";
import DragAndDropList from "./comp/DragAndDropList";
import Auth from "./pages/auth";
import ProtectedRoute from "./pages/ProtectedRoute.js";
import Dashboard from "./pages/dashboard";
import Clients from "./pages/agency-home/clients";
import Apm from "./pages/extensions/apm";
import api from "./pages/api/api";
import Settings from "./pages/settings/settings";
import LilyAI from "./pages/lily-ai/lilyai";
import Scoops from "./pages/agency-home/scoops";
import Campaigns from "./pages/agency-home/campaigns";
import PromptGenerator from "./pages/extensions/promptGenerator";
import WebAnalyser from "./pages/extensions/webAnalyser";
import Saio from "./pages/knowledge/saio";
import Eric from "./pages/knowledge/eric";
import Test from "./pages/test.js";
import LP from "./pages/LP.js";
function App() {
  const [session, setSession] = useState(null);

  useEffect(() => {
    supabase.auth.getSession().then(({ data: { session } }) => {
      setSession(session);
    });

    supabase.auth.onAuthStateChange((_event, session) => {
      setSession(session);
    });
  }, []);

  const renderProtectedRoute = (Component) => {
    return session ? <Component /> : <Navigate to="/login" />;
  };

  const SignOut = () => {
    const navigate = useNavigate();
  
    useEffect(() => {
      const signOutUser = async () => {
        try {
          await supabase.auth.signOut();
          setSession(null);
          navigate('/');
        } catch (error) {
          console.error('Error during sign-out:', error);
        }
      };
  
      signOutUser();
    }, [navigate]);
  
    return null; // or a loader/spinner if you prefer
  };


  return (
    <Router>
      <Routes>
        <Route path="/dnd" element={renderProtectedRoute(DragAndDropList)} />
        <Route path="/dashboard" element={renderProtectedRoute(Dashboard)} />

        <Route path="/clients" element={renderProtectedRoute(Clients)} />
        <Route path="/campaigns" element={renderProtectedRoute(Campaigns)} />
        <Route path="/apm" element={renderProtectedRoute(Apm)} />
        <Route path="/lily-ai" element={renderProtectedRoute(LilyAI)} />
        <Route path="/scoops" element={renderProtectedRoute(Scoops)} />
        <Route
          path="/prompt-generator"
          element={renderProtectedRoute(PromptGenerator)}
        />
        <Route
          path="/web-analyser"
          element={renderProtectedRoute(WebAnalyser)}
        />
        <Route path="/saio" element={renderProtectedRoute(Saio)} />
        <Route path="/api" element={renderProtectedRoute(api)} />
        <Route path="/eric" element={renderProtectedRoute(Eric)} />
        <Route path="/test" element={renderProtectedRoute(Test)} />
        <Route path="/settings" element={renderProtectedRoute(Settings)} />
        <Route path="/" element={<LP />} />
        <Route path="/login" element={<Auth/>} />
        <Route path="/signout" element={<SignOut />} />

      </Routes>
    </Router>
  );
}

export default App;

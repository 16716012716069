// src/components/DragAndDropList.js
import React, { useState } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

const initialItems = [
  { id: "item-1", content: "Item 1" },
  { id: "item-2", content: "Item 2" },
  { id: "item-3", content: "Item 3" },
];

const DragAndDropList = () => {
  const [items, setItems] = useState(initialItems); // Add state for the items
  const [itemsList2, setItemsList2] = useState([]);


  const handleDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
  
    const sourceListId = result.source.droppableId;
    const destListId = result.destination.droppableId;
  
    if (sourceListId === destListId) {
      // Dragging within the same list
      const reorderedItems = [...items];
      const [movedItem] = reorderedItems.splice(result.source.index, 1);
      reorderedItems.splice(result.destination.index, 0, movedItem);
  
      setItems(reorderedItems); // Update the state for the source list
    } else {
      // Dragging from list-1 to list-2
      const sourceListItems = [...items];
      const destListItems = [...itemsList2];
      const [movedItem] = sourceListItems.splice(result.source.index, 1);
  
      destListItems.splice(result.destination.index, 0, movedItem);
  
      setItems(sourceListItems); // Update the state for list-1
      setItemsList2(destListItems); // Update the state for list-2
    }
  };
  

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <div>
        <Droppable droppableId="list">
          {(provided) => (
            <ul
              {...provided.droppableProps}
              ref={provided.innerRef}
              className="space-y-4"
            >
              {items.map((item, index) => (
                <Draggable key={item.id} draggableId={item.id} index={index}>
                  {(provided) => (
                    <li
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      className="bg-white p-4 rounded-md shadow-md"
                    >
                      {item.content}
                    </li>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </ul>
          )}
        </Droppable>

        <Droppable droppableId="list-2">
          {(provided) => (
            <ul
              {...provided.droppableProps}
              ref={provided.innerRef}
              className="space-y-4"
            >
              {itemsList2.map((item, index) => (
                <Draggable key={item.id} draggableId={item.id} index={index}>
                  {(provided) => (
                    <li
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      className="bg-white p-4 rounded-md shadow-md"
                    >
                      {item.content}
                    </li>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </ul>
          )}
        </Droppable>
      </div>
    </DragDropContext>
  );
};

export default DragAndDropList;

import React from "react";
import Sidebar from "../../comp/sidebar";
import Body from "../../comp/Body";

function scoops() {
  return (
    <>
      <Sidebar></Sidebar>
      <div className="sm:ml-64 h-screen bg-white shadow rounded-lg">
        <div className="h-screen ">
          <iframe
            className="mx-auto my-auto w-1/2 h-full border-none"
            src="https://www.example.com"
            width="100%"
            height="100%"
          ></iframe>
        </div>
      </div>
    </>
  );
}

export default scoops;

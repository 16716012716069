import React from 'react'
import Sidebar from '../../comp/sidebar'
function eric() {
  return (
    <>
    <Sidebar></Sidebar>
    <div className="sm:ml-64 h-screen bg-white shadow rounded-lg">
      <div className="h-screen ">
        <iframe
          className="mx-auto my-auto w-full h-full border-none"
          src="https://saasautomation.gitbook.io/erics-outbound/"
          width="100%"
          height="100%"
        ></iframe>
      </div>
    </div>
  </>
  )
}

export default eric

import React, { useState, useEffect } from 'react';
import Sidebar from '../../comp/sidebar';
import supabase from '../../comp/supabaseClient';
import getApiKeyByEmail from "../../pages/agency-home/apiKey";

function Settings() {
  const [activeTab, setActiveTab] = useState('Users');
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const user = await supabase.auth.getUser();
        const userEmail = user.data.user.email;
          
        // Query parameter
        const queryParam = await getApiKeyByEmail(userEmail, "team_users");
  
        // Fetch data from AWS API
        const response = await fetch(`https://api.saasautomation.link/lily/teams?team_apikey=eq.${queryParam}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'x-api-key': queryParam,
          },
        });
  
        if (!response.ok) {
          throw new Error(`Error fetching users: ${response.statusText}`);
        }
  
        const data = await response.json();
  
        // Assuming the structure of the data received matches your expectations
        setUsers(data);
  
        setLoading(false);
      } catch (error) {
        console.error('Error fetching users:', error.message);
      }
    };
  
    fetchUsers();
  }, []);

  // Filtering users based on search term
  const filteredUsers = users.filter((user) =>
    user.user_email.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <>
      <Sidebar />
      <div className="sm:ml-64 h-screen bg-gray-100 shadow rounded-lg p-8">
        <div className="h-full flex flex-col">
          <h1 className="text-3xl font-bold mb-6">Settings</h1>
          <div className="flex mb-20">
            <div className="w-1/6">
              <div
                className={`cursor-pointer p-3 rounded ${
                  activeTab === 'Users'
                    ? 'bg-[#243ABD] text-white'
                    : 'bg-white text-gray-700 hover:bg-gray-200'
                } transition duration-500 ease-in-out`}
                onClick={() => setActiveTab('Users')}
              >
                Users
              </div>
              <div
                className={`cursor-pointer p-3 rounded ${
                  activeTab === 'Credentials'
                    ? 'bg-[#243ABD] text-white'
                    : 'bg-white text-gray-700 hover:bg-gray-200'
                } transition duration-500 ease-in-out mt-4`}
                onClick={() => setActiveTab('Credentials')}
              >
                Credentials
              </div>
            </div>
            <div className="w-4/5 pl-10">
              {activeTab === 'Users' && (
                <div>
                  <div className="flex items-center mb-4">
                    {/* Search bar */}
                    <input
                      type="text"
                      placeholder="Search users..."
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                      className="p-2 border rounded-md mr-4 focus:outline-none focus:border-[#243ABD] transition duration-300"
                      style={{ width: '700px' }}
                    />
                    {/* Add User button */}
                    <button className="bg-[#243ABD] rounded-md text-white px-4 py-2">
                      Add User
                    </button>
                  </div>

                  {/* List of users */}
                  {loading ? (
                    <p>Loading users...</p>
                  ) : (
                    <div>
                      {filteredUsers.map((user) => (
                        <div
                        key={user.id}
                        className="bg-white p-4 rounded-md shadow-md mb-4"
                        style={{ width: '700px' }}
                        >
                        {user.user_email}
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              )}
              {activeTab === 'Credentials' && (
                <div>
                  {/* Content for Credentials tab */}
                  <p className="text-lg">Under Production</p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Settings;

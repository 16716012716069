import React from 'react'
import Sidebar from '../../comp/sidebar'

function webAnalyser() {
  return (
    <>
    <Sidebar></Sidebar>
    <div className="sm:ml-64 h-screen bg-white shadow rounded-lg">
      <div className="h-screen ">
        <iframe
          className="mx-auto my-auto w-full h-full border-none"
          src="https://app.appsmith.com/app/ava-web-analyzer/dashboard-650dad52f3d421401941a7e2"
          width="100%"
          height="100%"
        ></iframe>
      </div>
    </div>
  </>
  )
}

export default webAnalyser

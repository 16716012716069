import React from 'react';
import cloudfare from "../../assets/cloudfare.png";

function GoogleData({ data }) {
  return (
    <div className="relative overflow-x-auto rounded-md">
      <table className="w-full text-sm text-left text-gray-500">
        {/* header */}
        <thead className="text-xs text-gray-400 bg-gray-50">
          <tr>
            <th scope="col" className="px-6 py-3">
              Google ID
            </th>
            <th scope="col" className="px-6 py-3">
              Client 
            </th>
            <th scope="col" className="px-6 py-3">
              Client Email
            </th>
            <th scope="col" className="px-6 py-3">
              Client Status
            </th>
          </tr>
        </thead>

        {/* body of table */}
        <tbody>
          {data.map((item) => (
            <tr key={item.id} className="bg-white border-b">
              <td className="px-6 py-4">{item.id}</td>
              <td className="px-6 py-4">{item.client_name}</td>
              <td className="px-6 py-4">{item.client_email}</td>
              <td className="px-6 py-4">{item.client_status}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default GoogleData;

import React, { useState } from "react";
import CloudflareData from "./apm-cloudflare.js";
import MicrosoftData from "./apm-microsoft.js";
import GoogleData from "./apm-google.js";

function Body({ data }) {
  const [activeTab, setActiveTab] = useState("LIST");

  return (
    <>
      {/* main body */}
      <div className="sm:ml-64 p-8 bg-white shadow rounded-lg">
        {/* tabs section */}
        <div className="flex mb-10 border-b-2">
      {/* Cloudflare Credentials Button */}
    <button
      className={`p-3 mr-2 text-sm font-medium transition-all duration-200 ease-in-out ${
        activeTab === "LIST"
          ? "text-blue-600 border-b-2 border-blue-600"
          : "text-gray-600 hover:text-blue-500"
      }`}
      onClick={() => setActiveTab("LIST")}
    >
      Cloudflare Credentials
    </button>

    <button
      className={`p-3 mr-2 text-sm font-medium transition-all duration-200 ease-in-out ${
        activeTab === "BOARD"
          ? "text-blue-600 border-b-2 border-blue-600"
          : "text-gray-600 hover:text-blue-500"
      }`}
      onClick={() => setActiveTab("MICROSOFT")}
    >
      Microsoft Credentials
    </button>

    <button
      className={`p-3 text-sm font-medium transition-all duration-200 ease-in-out ${
        activeTab === "BOARD2"
          ? "text-blue-600 border-b-2 border-blue-600"
          : "text-gray-600 hover:text-blue-500"
      }`}
      onClick={() => setActiveTab("GOOGLE")}
    >
      Google Credentials
    </button>

    </div>

        {/* tabs section */}

        {/* search bar */}
        {/* <h1 className="mb-5 text-lg font-semibold">Search Clients</h1>
        <div className="mb-8 flex">
          <input className="flex-grow w-4/5 mr-3 p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:border-blue-500 focus:ring-1 focus:ring-blue-500"></input>
          <button className="w-1/5 bg-blue-600 rounded-md py-2 px-5 text-white hover:bg-blue-700 transition duration-200 ease-in-out">
            Search
          </button>
        </div> */}
        {/* search bar */}

        {/* Table View Tabs */}
        <div className="tab-content">
          {activeTab === "LIST" && <CloudflareData data={data} />}
          {activeTab === "MICROSOFT" && <MicrosoftData data={data} />}
          {activeTab === "GOOGLE" && <GoogleData data={data} />}
        </div>
        {/* tabs */}
      </div>
      {/* main body */}
    </>
  );
}

export default Body;

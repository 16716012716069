import React from "react";
import Sidebar from "../../comp/sidebar";

function promptGenerator() {
  return (
    <>
      <Sidebar></Sidebar>
      <div className="sm:ml-64 h-screen bg-white shadow rounded-lg">
        <div className="h-screen ">
          <iframe
            className="mx-auto my-auto w-full h-full border-none"
            src="https://app.appsmith.com/app/luna/dashboard-64d3b74b99068a4844f32210?branch=master&embed=true"
            width="100%"
            height="100%"
          ></iframe>
        </div>
      </div>
    </>
  );
}

export default promptGenerator;

import React, { useState, useEffect } from "react";
import Sidebar from "../../comp/sidebar";
import Body from "../../comp/Body";
import getApiKeyByEmail from "./apiKey";
import supabase from "../../comp/supabaseClient";

const Clients = () => {
  // State to hold clients data
  const [clientsData, setClientsData] = useState([]);
  const [clientName, setClientName] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [clientEmail, setClientEmail] = useState("");
  const [clientTags, setClientTags] = useState("");
  const [clientStatus, setClientStatus] = useState("");

  // useEffect hook to fetch data on component mount
  useEffect(() => {
    fetchData();
  }, []);

  // Function to handle input field changes
  const handleInputChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    // Update the corresponding state variable based on the input field's name
    switch (name) {
      case "client_name":
        setClientName(value);
        break;
      case "company_name":
        setCompanyName(value);
        break;
      case "client_email":
        setClientEmail(value);
        break;
      case "client_tags":
        setClientTags(value);
        break;
      case "client_status":
        setClientStatus(value);
        break;
      default:
        break;
    }
  };

  // Function to handle form submission
  const handleFormSubmit = async () => {
    try {
      // Retrieve the current user's email
      const userEmail = await getUserEmail();
      // Retrieve the API key using the user's email
      const queryParam = await getApiKeyByEmail(userEmail, "team_users");

      // Prepare the data to send in the POST request
      const postData = {
        client_name: clientName,
        company_name: companyName,
        client_email: clientEmail,
        client_tags: clientTags,
        client_status: clientStatus,
        team_apikey: "awskaapikey20characters",
      };

      // Make the POST request to the API endpoint
      const response = await fetch(
        `https://api.saasautomation.link/lily/crm/clients?team_apikey=eq.${queryParam}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "x-api-key": queryParam,
          },
          body: JSON.stringify(postData),
        }
      );

      // Throw an error if the response is not okay
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      // Refresh the data by fetching it again after the POST request
      fetchData();
    } catch (error) {
      // Log any errors to the console
      console.error("Error submitting form: ", error);
    }
  };

  // Function to fetch clients data
  const fetchData = async () => {
    try {
      // Retrieve the current user's email
      const userEmail = await getUserEmail();
      // Retrieve the API key using the user's email
      const queryParam = await getApiKeyByEmail(userEmail, "team_users");
      // Fetch clients data using the API key
      const clients = await fetchClientsData(queryParam);
      // Update state with fetched clients data
      setClientsData(clients);
    } catch (error) {
      // Log any errors to the console
      console.error("Error fetching data: ", error);
    }
  };

  // Function to get the current user's email
  const getUserEmail = async () => {
    const user = await supabase.auth.getUser();
    return user.data.user.email;
  };

  // Function to fetch clients data from the API
  const fetchClientsData = async (queryParam) => {
    const response = await fetch(
      `https://api.saasautomation.link/lily/crm/clients?team_apikey=eq.${queryParam}`,
      {
        method: "GET",
        headers: {
          "x-api-key": queryParam,
        },
      }
    );

    // Throw an error if the response is not okay
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    // Return the JSON response
    return response.json();
  };

  // Render the Sidebar and Body components, passing clientsData to Body
  return (
    <>
      <Sidebar />
      <Body
        data={clientsData}
        searchForm={
          <>
            <h1 className="mb-5 text-lg font-semibold">Search Clients</h1>
            <div className="relative overflow-x-auto rounded-md">
              <table className="w-full text-sm text-left text-gray-500">
                {/* header */}
                <thead className="text-xs text-gray-400 bg-gray-50">
                  <tr>
                    <th scope="col" className="px-6 py-3">
                      ID
                    </th>
                    <th scope="col" className="px-6 py-3">
                      Client Name
                    </th>
                    <th scope="col" className="px-6 py-3">
                      Company Name
                    </th>
                    <th scope="col" className="px-6 py-3">
                      Client Email
                    </th>
                    <th scope="col" className="px-6 py-3">
                      Client Tags
                    </th>
                    <th scope="col" className="px-6 py-3">
                      Client Status
                    </th>
                  </tr>
                </thead>

                {/* body of table */}
                <tbody>
                  <tr className="bg-white border-b">
                    <td className="px-6 py-4">
                      <button
                        className="w-1/5 bg-blue-600 rounded-md py-2 px-5 text-white hover:bg-blue-700 transition duration-200 ease-in-out"
                        onClick={handleFormSubmit}
                      >
                        Update Database
                      </button>
                    </td>
                    <td className="px-6 py-4">
                      <input
                        onChange={handleInputChange}
                        className="outline outline-1 rounded h-6"
                        name="client_name"
                        value={clientName}
                      ></input>
                    </td>
                    <td className="px-6 py-4">
                      <input
                        onChange={handleInputChange}
                        className="outline outline-1 rounded h-6"
                        name="company_name"
                        value={companyName}
                      ></input>
                    </td>
                    <td className="px-6 py-4">
                      <input
                        onChange={handleInputChange}
                        className="outline outline-1 rounded h-6"
                        name="client_email"
                        value={clientEmail}
                      ></input>
                    </td>
                    <td className="px-6 py-4">
                      <input
                        onChange={handleInputChange}
                        className="outline outline-1 rounded h-6"
                        name="client_tags"
                        value={clientTags}
                      ></input>
                    </td>
                    <td className="px-6 py-4">
                      <input
                        onChange={handleInputChange}
                        className="outline outline-1 rounded h-6"
                        name="client_status"
                        value={clientStatus}
                      ></input>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </>
        }
      />
    </>
  );
};

export default Clients;

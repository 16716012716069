import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import Logo from "../assets/Logomark.svg";
import ChatIcon from "../assets/Chat.svg";
import ArrowIcon from "../assets/Arrow.svg";
import LogOutIcon from "../assets/LogOut.svg";
import supabase from "./supabaseClient";

// Dropdown Component
function Dropdown({
  title,
  links,
  isActivePath,
  activeDropdown,
  setActiveDropdown,
  userPermissions,
}) {
  const isChildActive = links.some(({ to }) => isActivePath(to));
  const isOpen = activeDropdown === title || isChildActive;

  return (
    <div
      className={
        isOpen
          ? "mb-3 cursor-pointer bg-[#243ABD] border-solid rounded-lg border border-[#4157DE]"
          : "mb-3 cursor-pointer"
      }
    >
      <div
        className="rounded-sm flex items-center justify-between p-2 text-white"
        onClick={() => setActiveDropdown(isOpen ? null : title)}
      >
        <div className="flex">
          <img className="mr-2" src={ChatIcon} alt="Icon" />
          <p>{title}</p>
        </div>
        <img src={ArrowIcon} alt="Arrow" />
      </div>
      {isOpen && (
        <div className="bg-[#243ABD] rounded-md overflow-hidden flex flex-col">
          {links.map(({ to, label, permissionKey }) => {
            // Check if the permissionKey is present in userPermissions
            if (!permissionKey || userPermissions[permissionKey]) {
              return (
                <Link
                  to={to}
                  key={label}
                  className={`rounded-md pl-3 text-[13px] mb-1 p-2 hover:bg-white hover:text-primary ${
                    isActivePath(to) ? "bg-white text-primary" : "text-white"
                  }`}
                >
                  {label}
                </Link>
              );
            } else {
              return null; // Do not render if permission key is not present
            }
          })}
        </div>
      )}
    </div>
  );
}

// Sidebar Component
function Sidebar() {
  const [userPermissions, setUserPermissions] = useState({});
  const [activeDropdown, setActiveDropdown] = useState(null);
  const location = useLocation();
  const isActivePath = (pathname) => location.pathname === pathname;

  const getUser = async () => {
    const user = await supabase.auth.getUser();
    return user.data.user;
  };

  const [userData, setUserData] = useState(null);

  useEffect(() => {
    getUser().then((user) => {
      setUserData(user);
    });
  }, []);

  useEffect(() => {
    const fetchUserPermissions = async () => {
      const user = await supabase.auth.getUser();
      if (user.data.user) {
        const userEmail = user.data.user.email;
        let { data, error } = await supabase
          .from("team_users")
          .select("sidebar")
          .eq("user_email", userEmail);

        if (error) {
          console.error("Error fetching user permissions:", error.message);
        } else if (data && data.length > 0) {
          setUserPermissions(data[0].sidebar);
        }
      }
    };

    fetchUserPermissions();
  }, []);

  const dropdowns = [
    {
      title: "Home",
      links: [
        { to: "/clients", label: "Clients", permissionKey: "home_clients" },
        { to: "/campaigns", label: "Campaigns", permissionKey: "home_campaigns" },
        { to: "/scoops", label: "Scoops", permissionKey: "home_scoops" },
      ],
      permissionKey: "home"
    },
    {
      title: "Extension",
      links: [
        { to: "/web-analyser", label: "Web Analyser", permissionKey: "extension_web_analyser" },
        { to: "/prompt-generator", label: "Prompt Generator", permissionKey: "extension_prompt_generator" },
        { to: "/apm", label: "Apm", permissionKey: "extension_apm" },
      ],
      permissionKey: "extension"
    },
    {
      title: "Knowledge",
      links: [
        { to: "/saio", label: "Agency Automation", permissionKey: "knowledge_agency_automation" },
        { to: "/eric", label: "Outbound Automation", permissionKey: "knowledge_outbound_automation" },
      ],
      permissionKey: "knowledge"
    },
    {
      title: "Api Hub",
      links: [{ to: "/api", label: "Documentation", permissionKey: "apidoc" }],
      permissionKey: "apihub"
    },
    
  ];

  const chatWithLilyButton = userPermissions["chat"] ? (
    <Link to="/lily-ai" key="ChatLink">
      <div
        className={`flex mb-3 p-2 text-white ${
          isActivePath("/lily-ai")
            ? "bg-[#243ABD] border-solid rounded-lg border border-[#4157DE]"
            : ""
        } `}
      >
        <img className="mr-2" src={ChatIcon} alt="Chat" />
        <p>Chat With Lily</p>
      </div>
    </Link>
  ) : null;

  const renderDropdowns = () => {
    return dropdowns.map((dropdown) => {
      if (dropdown.permissionKey && userPermissions[dropdown.permissionKey]) {
        return (
          <Dropdown
            key={dropdown.title}
            {...dropdown}
            isActivePath={isActivePath}
            activeDropdown={activeDropdown}
            setActiveDropdown={setActiveDropdown}
            userPermissions={userPermissions}
          />
        );
      } else if (!dropdown.permissionKey) {
        return (
          <Dropdown
            key={dropdown.title}
            {...dropdown}
            isActivePath={isActivePath}
            activeDropdown={activeDropdown}
            setActiveDropdown={setActiveDropdown}
            userPermissions={userPermissions}
          />
        );
      }
      return null;
    });
  };

  return (
    <aside
      id="sidebar-multi-level-sidebar"
      className="fixed top-0 left-0 z-40 w-64 h-screen transition-transform -translate-x-full sm:translate-x-0"
      aria-label="Sidebar"
    >
      <div className="h-full overflow-y-auto bg-primary flex flex-col">
        <ul className="p-3 h-full font-medium flex flex-col">
          <li>
            <a
              href="/"
              className="flex border-b border-white-300 rounded-none items-center py-4 px-4 text-gray-90 group"
            >
              <img
                alt="Logo"
                src={Logo}
                className="w-9 transition duration-75"
              />
              <span className="ml-3 font-semibold text-white text-2xl">
                LILY
              </span>
            </a>
          </li>
          <div className="h-full flex flex-col mt-6 py-2 px-1">
            {chatWithLilyButton}
            {renderDropdowns()}
            <Link to="/settings">
              <div
                className={`flex mb-3 p-2 text-white ${
                  isActivePath("/settings")
                    ? "bg-[#243ABD] border-solid rounded-lg border border-[#4157DE]"
                    : ""
                } `}
              >
                <img className="mr-2" src={ChatIcon} alt="Chat" />
                <p>Settings</p>
              </div>
            </Link>
          </div>
          <li className="rounded-lg p-2">
            <Link to="/signout" className="flex justify-between">
              <div className="flex items-center">
                {userData ? (
                  <img
                    className="w-8 h-8 rounded-full"
                    src={userData.user_metadata.avatar_url}
                    alt="Avatar"
                  />
                ) : (
                  <img
                    className="w-8 h-8 rounded-full"
                    src="https://e7.pngegg.com/pngimages/799/987/png-clipart-computer-icons-avatar-icon-design-avatar-heroes-computer-wallpaper-thumbnail.png"
                    alt="Avatar"
                  />
                )}

                {userData ? (
                  <p className="ml-3 text-white">
                    {userData.user_metadata.full_name}
                  </p>
                ) : (
                  <p className="ml-3 text-white">Loading...</p>
                )}
              </div>
              <img src={LogOutIcon} alt="LogOut" />
            </Link>
          </li>
        </ul>
      </div>
    </aside>
  );
}

export default Sidebar;

import React from "react";
import { Link } from "react-router-dom";

function LP() {
  return (
    <div className="font-chakra-petch h-screen px-32 py-16 bg-[#0B0B0F]">
      {/* Use RGBA for background color */}
      <header className=" bg-[#2F2C2F] rounded-xl px-11 py-6 justify-between flex ">
        <div>
          <Link to='/dashboard' className="text-white">Lilly</Link>
        </div>
        <div>
          <Link className=" mr-10  text-white">Pricing</Link>
          <Link className=" mr-10  text-white">Skills</Link>
          <Link className=" mr-10  text-white">Community</Link>
          <Link to='/dashboard' className=" rounded-md text-white bg-[#E35B1C] p-3 ">
            Hire Lily
          </Link>
        </div>
      </header>

      <body className="flex mt-32 items-center px-11 text-white flex-row justify-between">
        <div className="  max-w-[50%] ">
          <h1 className=" font-semibold text-6xl mb-7">
            Helping B2B Lead Gen Agencies scale their 
            Operations.
          </h1>
          <p className=" text-lg mb-11">
            Lore Ipsum, trust me lorep ipsum Lore dIpsum, tdrust me loredp
            
            ipsudfdm Lore Ipsum, trudfst mdfddfdp ipdfsum Lore Idpdfsum
            um Lore Ipddsum, dfsum um Lore Ipddsum,
          </p>
          <Link to='/dashboard' className=" rounded-md font-semibold  text-white bg-[#E35B1C] p-4 ">
            Hire Lily
          </Link>
        </div>
        <div className="w-[650px] p-5 rounded-lg border border-orange-600 h-[550px]">
          <iframe
            src="https://bot.typebot.kinge.work/lead-generation-va71zk9"
            className="mx-auto my-auto w-full h-full border-none"
            style={{ backgroundColor: "transparent" }}
          ></iframe>
        </div>
      </body>
    </div>
  );
}

export default LP;
